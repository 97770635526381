import React from 'react';
import './Home-Header.css'; // replace with the path to your CSS file
import { useNavigate } from 'react-router-dom';



function Header() {
  const navigate = useNavigate();
  
  function scrollToProduct() {
    if (window.location.pathname !== '/') {
      navigate('/');
    }
    setTimeout(() => {
      document.getElementById('product').scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  return (
    <header className="main-header">
      <h1 className="title">Automatic Global Entry Interview Notifications and Bookings</h1>
      <h2 className="subtitle5">Book Global Entry interviews the moment they open up</h2>
      <div className="button-container">
        <button onClick={scrollToProduct} className="get-started-btn">Enroll Now</button>
        {/* <button className="learn-more-btn" onClick={scrollToLottie}>Learn More</button> */}
      </div>
    </header>
  );
}

export default Header;
