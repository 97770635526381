import React from 'react';
import StudentOnboarding from './StudentOnboarding';  // Import the SignUp component
import './SignUpPage.css';  // Import the CSS for this component

function StudentOnboardingPage() {
    return (
        <div className="signup-page">
            <StudentOnboarding />
        </div>
    );
}

export default StudentOnboardingPage;
