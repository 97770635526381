import React from 'react';
import './LottieComponent.css'; // Import your CSS file

function LottieComponent() {
  return (
    <div className="lottie-container">
      <div className="lottie-content">   
        <lottie-player 
          src="https://lottie.host/525e18a0-1793-4c7a-9cc7-acafa83859ba/aue0hdZm6X.json" 
          background="transparent" 
          speed="1" 
          className="lottie-player" // Use className to apply styles
          loop 
          autoplay>
        </lottie-player>
        <div className="lottie-text">
          <h1>Global Entry interviews can take months.</h1>
          <p>
          Due to the program's popularity, the demand for interviews has surged, leading to unbelievable wait times.
          </p>
        </div>
      </div>
    </div>
  );
}

export default LottieComponent;
