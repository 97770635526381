import React from 'react';
import AdminOnboarding from './AdminOnboarding';  // Import the SignUp component
import './SignUpPage.css';  // Import the CSS for this component

function AdminOnboardingPage() {
    return (
        <div className="signup-page">
            <AdminOnboarding />
        </div>
    );
}

export default AdminOnboardingPage;
