import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import buttonStyle from './Button';
import darkbuttontext from './dark-button-text';


function isLoggedIn() {
    return !!localStorage.getItem('token');
  }
  
function Login() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  console.log(process.env.REACT_APP_API_ENDPOINT)
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    if (isLoggedIn()) {
        console.log("Already Logged In")
    }
    event.preventDefault();
    try {
      const response = await axios.post(`${API_ENDPOINT}/api/login/`, {
        username,
        password,
      });
      localStorage.setItem('token', response.data.token);
      console.log(response.data.user_id)
      navigate('/onboarding');
    } catch (error) {
      console.error('Error logging in', error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          Username:
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </label>
        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </label>
        <button type="submit" style={buttonStyle}>
          <span style={darkbuttontext}>Login</span>
        </button>
      </form>
    </div>
  );
}

export default Login;
