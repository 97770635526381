import React, { useState } from 'react';
import axios from 'axios';

function AddSchool() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [schoolName, setSchoolName] = useState('');
  const [schoolLocation, setSchoolLocation] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!schoolName.trim() || !schoolLocation.trim()) {
      alert('Please enter the name and location of the school.');
      return;
    }

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/api/schools/`, 
        {
            name: schoolName, 
            location: schoolLocation,
            educators: [localStorage.getItem('user_id')],
            students: [],
            offerings: []
        },
        {
            headers: {
                'Authorization': 'Token ' + localStorage.getItem('token')
            }
        }
    );
        } catch (error) {
      console.error('There was an error!', error);
      alert('There was an error adding the school.');
    }

    setSchoolName('');
    setSchoolLocation('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={schoolName}
        onChange={(e) => setSchoolName(e.target.value)}
        placeholder="Enter school name"
      />
      <input
        type="text"
        value={schoolLocation}
        onChange={(e) => setSchoolLocation(e.target.value)}
        placeholder="Enter school location"
      />
      <button type="submit">Add School</button>
    </form>
  );
}

export default AddSchool;
