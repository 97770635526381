import React from 'react';

function Product({ animationData, title, description }) {
  // Default options for the Lottie component

  return (
    <div className="product">
      <lottie-player 
          src={animationData}
          background="transparent" 
          speed="1" 
          style={{height: '200px', flexShrink: 0 }}
          loop 
          autoplay>
        </lottie-player>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  );
}

export default Product;
