import React, { useState } from 'react';
import logo from '../assets/images/logo2.png'; // replace with the path to your logo image
import './Navbar.css'; // replace with the path to your CSS file
import { useNavigate } from 'react-router-dom';

function Header2() {

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);


  function toggleMobileMenu() {
    setMobileMenuOpen(!mobileMenuOpen);
  }


  let navigate = useNavigate();

  function signup(){
    navigate('/sign-up')
  }
  
  function scrollToProduct() {
    if (window.location.pathname !== '/') {
      navigate('/');
    }
    setTimeout(() => {
      document.getElementById('product').scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  function scrollToPricing() {
    if (window.location.pathname !== '/') {
      navigate('/');
    }
    setTimeout(() => {
      document.getElementById('pricing').scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  function scrollToContact() {
    if (window.location.pathname !== '/') {
      navigate('/');
    }
    setTimeout(() => {
      document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }
  
  return (
    <header className="header">
      <a href="/" className="nav-link">
        <div className="logo">EASY GLOBAL ENTRY</div>
      </a>
      
      <div className="menu-icon" onClick={toggleMobileMenu}>
        { mobileMenuOpen ? 'X' : '☰' }
      </div>
      
      <nav className="nav">
        <a onClick={scrollToProduct} className="nav-link">Products</a>
        <a onClick={scrollToContact} className="nav-link">Contact</a>
        <a onClick={scrollToProduct} className="login-signup-btn">Enroll Now</a>
      </nav>
    </header>
  );
}


export default Header2;
