import React from 'react';
import Product from './Product';
import './FAQ.css';

function OurProducts() {
  return (
    <div className="container">
      <h1 className="subtitle3">Our Products</h1>
      <div className="products-container">
      <Product 
        animationData={"https://lottie.host/c99986c6-0d71-4ce7-a82e-d2ac7fb0f30e/UdkZYiBiRZ.json"}
        title="Notify"
        description="Receive instant SMS notifications whenever there is a last-minute cancellation or a new interview becomes available."
      />
      <Product 
        animationData={"https://lottie.host/daa2b073-0b61-4553-bfaf-f1bef14fb685/Z0m1Tr3Pwh.json"}
        title="AutoBook"
        description="At popular locations, interviews are booked in seconds. AutoBook takes the stress out of securing an interview by instantly booking for you."
      />
    </div>
    </div>
  );
}

export default OurProducts;
