import React from 'react';
import HomeHeader from './Home-Header';
import LottieComponent from './LottieComponent';
import LottieComponent2 from './LottieComponent2';
import LottieComponent3 from './LottieComponent3';
import FAQ from './FAQ'
import Pricing from './Pricing'
import Footer from './Footer'
import ContactPage from './ContactPage';

function HomePage() {
    return (
        <div style={{ padding: '10px' }}>
            <HomeHeader />
            <div id="lottie-component">
                <LottieComponent />
            </div>
            <LottieComponent2 />
            <LottieComponent3 />
            <div id="product">
                <FAQ />
            </div>
            <div id="pricing">
                <Pricing />
            </div>
            <div id="contact">
                <ContactPage />
            </div>
            <Footer />
        </div>
    );
}

export default HomePage;
