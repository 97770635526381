import React from 'react';
import { useNavigate } from 'react-router-dom';

function Onboarding1 () {
    const navigate = useNavigate();

    const handleAddSchool = () => {
        console.log('Add new school button clicked');
        navigate('/addschool');
    }

    const handleJoinSchoolStudent = () => {
        console.log('Join school as student button clicked');
        navigate('/joinschool', { state: { role: 'student' } });
    }

    const handleJoinSchoolAdmin = () => {
        console.log('Join school as administrator button clicked');
        navigate('/joinschool', { state: { role: 'educator' } });
    }

    return (
        <div>
            <button style={{ fontSize: '20px', padding: '10px 20px' }} onClick={handleAddSchool}>
                Add new school
            </button>
            <button style={{ fontSize: '20px', padding: '10px 20px', marginLeft: '10px' }} onClick={handleJoinSchoolStudent}>
                Join school as student
            </button>
            <button style={{ fontSize: '20px', padding: '10px 20px', marginLeft: '10px' }} onClick={handleJoinSchoolAdmin}>
                Join school as administrator
            </button>
        </div>
    );
}

export default Onboarding1;
