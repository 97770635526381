const button = {
    boxSizing: "border-box",
    flexShrink: 0,
    width: "min-content", /* 109px */
    height: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px 15px 15px 15px",
    boxShadow: "0px 0.7065919983928324px 0.7065919983928324px -0.625px rgba(0, 0, 0, 0.15), 0px 1.8065619053231785px 1.8065619053231785px -1.25px rgba(0, 0, 0, 0.14398), 0px 3.6217592146567767px 3.6217592146567767px -1.875px rgba(0, 0, 0, 0.13793), 0px 6.8655999097303715px 6.8655999097303715px -2.5px rgba(0, 0, 0, 0.12711), 0px 13.646761411524492px 13.646761411524492px -3.125px rgba(0, 0, 0, 0.10451), 0px 30px 30px -3.75px rgba(0, 0, 0, 0.05)",
    backgroundColor: "#222222",
    overflow: "visible",
    position: "relative",
    alignContent: "center",
    flexWrap: "nowrap",
    gap: 10,
    borderRadius: 8,
  }

  export default button;