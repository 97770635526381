import React from 'react';
import SearchHeader from './SearchHeader'; 
import CardSection from './CardSection';

function HomePage() {
    
    const course = {
        id: 1,  // Added id property
        title: "The Chemistry of Coffee",
        description: "In this course we will learn the principles of chemistry through studying how coffee is farmed and brewed.",
        image: "https://images.unsplash.com/photo-1689611112440-8d7e2946e360?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHw5fHx8ZW58MHx8fHx8&auto=format&fit=crop&w=800&q=60",  // Placeholder image URL
        school: "Lake Tahoe Community College"
    };
    const courseSections = [
        {
            title: "EdXcelerate High School Essentials",  // Each section needs a title
            courses: [course, course, course, course, course, course]  // The courses property should be an array of course objects
        }
    ];


    return (
        <div>
            <SearchHeader />
            {courseSections.map(section => (
                <CardSection key={section.title} title={section.title} courses={section.courses} />
            ))}
            {courseSections.map(section => (
                <CardSection key={section.title} title={section.title} courses={section.courses} />
            ))}
        </div>
    );
}

export default HomePage;
