import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const ProtectedRoute = ({children}) => {
  const navigate = useNavigate();
  const isLoggedIn = !!localStorage.getItem('token');
  console.log(localStorage.getItem('token'))
  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }
  }, [isLoggedIn, navigate]); // adding navigate as a dependency is optional

  return isLoggedIn ? children : null;
};

export default ProtectedRoute;
