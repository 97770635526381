import React from 'react';
import './LottieComponent.css'; // Import your CSS file

function LottieComponent() {
  return (
    <div className="lottie-container">
      <div className="lottie-content">
        <div class="upper">
        <lottie-player 
          src="https://lottie.host/b33419c7-80f9-4412-a267-f9082560f377/8Rz7StQqEz.json" 
          background="transparent" 
          speed="1" 
          className="lottie-player" // Use className to apply styles
          loop 
          autoplay>
        </lottie-player>
          </div>          
        <div className="lottie-text">
          <h1>New appointments open up every day.</h1>
          <p>
          Applicants often will cancel or reschedule their interviews, creating new available slots on a daily basis.
          </p>
        </div>
        <div class="lower">
        <lottie-player 
          src="https://lottie.host/b33419c7-80f9-4412-a267-f9082560f377/8Rz7StQqEz.json" 
          background="transparent" 
          speed="1" 
          className="lottie-player" // Use className to apply styles
          loop 
          autoplay>
        </lottie-player>
        </div>
      </div>
    </div>
  );
}

export default LottieComponent;

