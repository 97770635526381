import React from 'react';
import Card from 'react-bootstrap/Card';
import './CourseCard.css'; // Import the CSS for this component

function CourseCard({ course }) {
    // Destructure the course object to get the details of each course
    const { title, description, image, school } = course;

    return (
        <Card className="course-card">
            <div className="card-img" style={{ backgroundImage: `url(${image})` }}></div>
            <Card.Body>
                <Card.Subtitle className="mb-2">{school}</Card.Subtitle>
                <Card.Title>{title}</Card.Title>
                <Card.Text>{description}</Card.Text>
                {/* You can add more elements like a link or button here */}
            </Card.Body>
        </Card>
    );
}

export default CourseCard;
