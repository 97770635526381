import React, { useState } from 'react';
import './SearchHeader.css';  // Import the CSS for this component

function SearchPage() {
    const [searchQuery, setSearchQuery] = useState('');  // State for the search input

    const handleSearchInput = (event) => {  // Update the search input state when the input changes
        setSearchQuery(event.target.value);
    };

    const handleSearch = () => {  // Function to handle search when the button is clicked
        // Implement your search logic here
        console.log('Search:', searchQuery);
    };

    return (
        <div className="search-page">
            <h1 className="subtitle">Get started with dual enrollment today</h1>
            <h2 className="subheading">Find advanced courses that can transfer to your high school</h2>
            <div className="search-bar">
                <input 
                    type="text" 
                    placeholder="Search dual enrollment courses" 
                    value={searchQuery} 
                    onChange={handleSearchInput} 
                />
                <button className="get-started-btn" onClick={handleSearch}>Search</button>
            </div>
        </div>
    );
}

export default SearchPage;
