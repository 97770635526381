import React from 'react';

function Footer() {
    const style = {
        margin: '50px 0'  /* adjust this value as needed */
    };

    return (
        <footer style={style}>
            © 2023 Easy Global Entry
        </footer>
    );
}

export default Footer;
