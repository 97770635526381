import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './Pricing.css';
import Card from 'react-bootstrap/Card';


function PricingCard({ productName, subtext, price, features, button, onBuyNow }) {    
    return (
        <Card className="course-card">
            <div className="card-content d-flex align-items-center justify-content-center">
                <Card.Body>
                    <Card.Subtitle className="text-center">{productName}</Card.Subtitle>
                    <div className="price-container d-flex align-items-center justify-content-center">
                        <span className="price">{price}</span>
                        <span className="one-time">{subtext}</span>
                    </div>
                    <div className="features">
                        {features.map((feature, index) => (
                        <div key={index} className="feature-item d-flex align-items-center">
                            <FontAwesomeIcon icon={faCheckCircle} color="black" />
                            <span>{feature}</span>
                        </div>
                        ))}
                    </div>
                    <button className="buy-now" onClick={onBuyNow}>{button}</button>
                </Card.Body>
            </div>
        </Card>
    );
}

export default PricingCard;
