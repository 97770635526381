import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom'; 

function JoinSchool() {
    
  const [schools, setSchools] = useState([]);
  const [selectedSchool, setSelectedSchool] = useState('');
  const location = useLocation();
  const role = location.state.role; 
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/schools/`, {
          headers: {
            'Authorization': 'Token ' + localStorage.getItem('token')
          },
        });
        setSchools(response.data);
      } catch (error) {
        console.error('Error fetching schools', error);
      }
    };

    fetchSchools();
  }, []);

  const handleJoinSchool = async () => {
    if (!selectedSchool) {
      alert('Please select a school.');
      return;
    }

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/api/schools/${selectedSchool}/join/${localStorage.getItem('user_id')}/${role}/`,
        {},
        {
          headers: {
            'Authorization': 'Token ' + localStorage.getItem('token'),
          },
        }
      );  
      console.log(response.data);
      alert(`Successfully joined school as ${role}!`);
    } catch (error) {
      console.error('Error joining school', error);
    }
  };

  return (
    <div>
      <select value={selectedSchool} onChange={(e) => setSelectedSchool(e.target.value)}>
        <option value="">Select a school</option>
        {schools.map((school) => (
          <option key={school.id} value={school.id}>
            {school.name}
          </option>
        ))}
      </select>
      <button onClick={handleJoinSchool}>Join School</button>
    </div>
  );
}

export default JoinSchool;
