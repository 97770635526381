import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import './SignUp.css';  // Import the CSS for this component
import './SignUpPage.css';  // Import the CSS for this component

function SignUpPage() {
    return (
        <div className="signup-page">
            <Container className="signup-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <lottie-player 
                src="https://lottie.host/e42c7a15-0e60-4c7d-bf61-72e5b3ba1fbe/UR9L0OEutA.json" 
                background="transparent" 
                speed="1" 
                style={{ width: '15%', flexShrink: 0 }}
                loop 
                autoplay>
            </lottie-player>
            <h2 className="center-text">You have successfully joined Easy Global Entry!</h2>
            <p className="center-text">You will start receiving email notifications about interview times as soon as they open up.</p>
        </Container>
        </div>
    );
}

export default SignUpPage;