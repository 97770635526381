import React from 'react';
import CourseCard from './CourseCard';  // Import the CourseCard component
import './CardSection.css';  // Import the CSS for this component

function CardSection({ title, courses }) {
    return (
        <div className="card-section">
            <h1>{title}</h1>
            <div className="card-list">
                {courses.map(course => <CourseCard key={course.id} course={course} />)}
            </div>
        </div>
    );
}

export default CardSection;
