const darkbuttontext = {
    flexShrink: 0,
    width: "auto", /* 79px */
    height: "auto", /* 17px */
    whiteSpace: "pre",
    position: "relative",
    fontWeight: 600,
    fontStyle: "normal",
    fontFamily: `"Inter", sans-serif`,
    color: "#ffffff",
    fontSize: 14,
    letterSpacing: "0em",
    lineHeight: 1.2,
    textAlign: "center",
  }

  export default darkbuttontext;