import React, { useState } from 'react';
import axios from 'axios';

function RegistrationForm() {
    const [form, setForm] = useState({
        username: '',
        password: '',
        email: ''
    });

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:8000/api/register/', form);
            console.log(response.data);  // 'User created successfully'
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <label>
                Username:
                <input type="text" name="username" value={form.username} onChange={handleChange} />
            </label>
            <label>
                Password:
                <input type="password" name="password" value={form.password} onChange={handleChange} />
            </label>
            <label>
                Email:
                <input type="email" name="email" value={form.email} onChange={handleChange} />
            </label>
            <button type="submit">Register</button>
        </form>
    );
}

export default RegistrationForm;
