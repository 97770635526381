import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import './SignUp.css';  // Import the CSS for this component

function StudentOnboarding() {
    return (
        <Container className="signup-container">
            <h2 >Welcome to EdXcelerate!</h2>
            <p >Join your school to start discovering transferable courses.</p>
            <Form>
                <Form.Label className="bold-label">What school do you attend?</Form.Label>
                <Form.Select className="mb-3" aria-label="Default select example">
                    <option value="1">South Tahoe High</option>
                </Form.Select>
                <div className="center-text">
                    <Button variant="primary" type="submit" className="btn-block custom-button">
                        Join School
                    </Button>
                </div>
            </Form>
        </Container>
    );
}

export default StudentOnboarding;
