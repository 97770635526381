import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header'; // Import the Header component
import ExploreCourses from './components/ExploreCourses';
import MyPathways from './components/MyPathways';
import NewPathway from './components/NewPathway';
import RegistrationForm from './components/Register'; 
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import Onboarding1 from './components/Onboarding1';
import AddSchool from './components/AddSchool'
import JoinSchool from './components/JoinSchool'
import Navbar from  './components/Navbar'
import HomeHeader from './components/Home-Header';
import LottieComponent from './components/LottieComponent';
import HomePage from './components/HomePage'; 
import SearchPage from './components/SearchPage';
import ContactPage from './components/ContactPage';
import SignUpPage from './components/SignUpPage';
import Confirmed from './components/Confirmed';
import Confirmed2 from './components/Confirmed2';
import StudentOnboardingPage from './components/StudentOnboardingPage';
import AdminOnboardingPage from './components/AdminOnboardingPage';
import CoursePage from './components/CoursePage';
import ContactPage2 from './components/ContactPage2';
import Locations from './components/Locations'; 
import Calendar from './components/Calendar'; 
import Connect from './components/Connect'; 
import Connect2 from './components/Connect2'; 
import Connect3 from './components/Connect3'; 
import Privacy from './components/Privacy'; 


function App() {
  return (
    <div className="App">
      <Router>
        {/* <Header /> */}
        <Navbar />
        <Routes>  
          <Route path="/" element={<HomePage />} />
          <Route path="/explore-courses" element={<ProtectedRoute><ExploreCourses /></ProtectedRoute>} />
          <Route path="/my-pathways" element={<ProtectedRoute><MyPathways /></ProtectedRoute>} />
          <Route path="/new-pathway" element={<ProtectedRoute><NewPathway /></ProtectedRoute>} />
          <Route path="/register" element={<RegistrationForm />} />
          <Route path="/login" element={<Login />} />
          <Route path="/onboarding" element={<Onboarding1 />} />
          <Route path="/addschool" element={<AddSchool />} />
          <Route path="/joinschool" element={<JoinSchool />} />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/contact" element={<ContactPage2 />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/confirmed" element={<Confirmed />} />
          <Route path="/confirmed2" element={<Confirmed2 />} />
          <Route path="/student-onboarding" element={<StudentOnboardingPage />} />
          <Route path="/admin-onboarding" element={<AdminOnboardingPage />} />
          <Route path="/my-school" element={<AdminOnboardingPage />} />
          <Route path="/locations" element={<Locations />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/connect" element={<Connect />} />
          <Route path="/connect2" element={<Connect2 />} />
          <Route path="/connect3" element={<Connect3 />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </Router>
    </div> 
  );  
}

export default App;
