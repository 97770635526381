import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import connect from '../assets/images/Connect.png';
import './SignUp.css';  // Import the CSS for this component
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';

function SignUp() {
    const navigate = useNavigate();

    const times = JSON.parse(localStorage.getItem('selectedSlots') || '[]');
    const email = localStorage.getItem('userEmail');
    const token = localStorage.getItem('token');
    const locations = JSON.parse(localStorage.getItem('locations'));

    

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const convertToISOFormat = (timeStr) => {
        const parts = timeStr.split(' ');
        if (parts.length !== 5) {
            console.error("Unexpected date format:", timeStr);
            return timeStr;
        }
        
        const [day, month, dayNumber, year, timePart] = parts;
        let [hours, minutes] = timePart.split(':');
        const period = minutes.slice(-2); // Extract am/pm
        minutes = minutes.slice(0, -2);  // Remove am/pm
    
        hours = parseInt(hours);
        if (period.toLowerCase() === 'pm' && hours !== 12) {
            hours += 12;
        }
        if (period.toLowerCase() === 'am' && hours === 12) {
            hours = 0;
        }
    
        const dateObj = new Date(`${month} ${dayNumber}, ${year} ${String(hours).padStart(2, '0')}:${minutes}`);
        return dateObj.toISOString();
    };

    
    const convertedTimes = times.map(timeStr => convertToISOFormat(timeStr));

    
    const handleConnect = async (event) => {
        event.preventDefault();
        console.log(locations)

        try {
            const response = await axios.post(`${API_ENDPOINT}/api/register/`, {
                email,
                subscribed_locations: locations,
                product: 'AUTOBOOK',
                token: token,
                times: convertedTimes,
            }); 
            
            window.location.href = response.data.url;
            
        } catch (error) {
            console.error('Error during registration:', error);
        }

    };

    return (
        <div className="signup-page">
        <Container className="signup-container2">
            <img src={connect} className='connect-img' alt="Connect" />
            <Container className="signup-container" style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <lottie-player 
                src="https://lottie.host/e42c7a15-0e60-4c7d-bf61-72e5b3ba1fbe/UR9L0OEutA.json" 
                background="transparent" 
                speed="1" 
                style={{ width: '15%', flexShrink: 0 }}
                loop 
                autoplay>
            </lottie-player>
            <h2 className="center-text">Connection Successful</h2>
            <p style={{textAlign: 'center'}}>Proceed to purchase to complete Autobook enrollment.</p>
            <Form onSubmit={handleConnect}>                
                <div className="center-text">
                    <Button variant="primary" type="submit" className="btn-block custom-button">
                        Proceed to Purchase
                    </Button>
                </div>
            </Form>
        </Container>
            
        </Container>
        </div>
    );
}

export default SignUp;
