import React from 'react';
import PricingCard from './PricingCard';
import './Pricing.css';
import { Link, useNavigate } from 'react-router-dom';




function Pricing() {
    const navigate = useNavigate();
  const productData = [
    {
      productName: "Notify",
      subtext: "one time",
      price: "$2",
      features: ["Up to 50 email notifications", "Up to 3 enrollment centers", "Unsubscribe at any time"],
      button: "Buy Now",
      onBuyNow: () => { navigate('/sign-up') }
    }, 
    {
      productName: "AutoBook",
      subtext: "one time",
      price: "$25",
      features: ["Automatic interview booking", "Select times that work for you", "Guaranteed within two weeks"],
      button: "Buy Now",
      onBuyNow: () => { navigate('/locations') 
        window.scrollTo(0, 0)
    }
    }
  ];

  return (
    <div className="faq-container">
      <h1 className="subtitle3">Pricing</h1>
      <div className="pricing">
      {productData.map((data, index) => (
        <PricingCard key={index} {...data} />
      ))}
    </div>
    </div>

    
  );
}

export default Pricing;
