import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import connect from '../assets/images/Connect.png';
import './SignUp.css';  // Import the CSS for this component
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';

function SignUp() {
    const navigate = useNavigate();
    const location = useLocation();
    const [mfa_code, setMFA] = useState('');
    const [token, setToken] = useState('');
    const times = JSON.parse(localStorage.getItem('selectedSlots') || '[]');
    const email = localStorage.getItem('userEmail');

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    
    const handleConnect = async (event) => {
        event.preventDefault();  // Prevent the default form submission behavior

        const previousResponse = location.state.previousResponse;

        const payload = {
            mfa_url: previousResponse.mfa_url,
            idp_session: previousResponse.idp_session,
            authenticity_token: previousResponse.authenticity_token,
            sp_issuer: previousResponse.sp_issuer,
            form_parameters: previousResponse.form_parameters,
            mfa_code: mfa_code
        };

        try {
            const response = await axios.post(`${API_ENDPOINT}/api/globalentry/mfa/`, payload);

            if (response.status === 200) {
                const receivedToken = response.data.token;
                setToken(receivedToken);
                localStorage.setItem('token', receivedToken);
                navigate("/connect3");
            }
        } catch (error) {
            alert("Failed to connect. Please check your MFA code and try again.");
        }

    };

    useEffect(() => {
        
    }, []);

    return (
        <div className="signup-page">
        <Container className="signup-container2">
            <img src={connect} className='connect-img' alt="Connect" />
            <h2 className="center-text">Connect to Login.gov</h2>
            <div className="disclaimer">
                We require your Login.gov information to automatically book on your behalf. To learn more please see our <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
            </div>
            <p></p>
            <Form onSubmit={handleConnect}>
                <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label className="bold-label">Login.gov Two-Factor Authentication Code</Form.Label>
                    <Form.Control value={mfa_code} onChange={e => setMFA(e.target.value)} required/>
                </Form.Group>


                <div className="center-text">
                    <Button variant="primary" type="submit" className="btn-block custom-button">
                        Connect
                    </Button>
                </div>
            </Form>
        </Container>
        </div>
    );
}

export default SignUp;
