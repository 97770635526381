import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import './SignUp.css';  // Import the CSS for this component

function SignUp() {
    return (
        <Container className="signup-container">
            <h2>Welcome to EdXcelerate!</h2>
            <p>Create your school page to start offering transferable courses.</p>
            <Form>
                <Form.Group className="mb-3" controlId="formFirstName">
                    <Form.Label className="bold-label">What is the name of your school?</Form.Label>
                    <Form.Control type="text" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formLastName">
                    <Form.Label className="bold-label">What state is your school in?</Form.Label>
                    <Form.Control type="text" />
                </Form.Group>
                <div className="center-text">
                    <Button variant="primary" type="submit" className="btn-block custom-button">
                        Create School
                    </Button>
                </div>
            </Form>
        </Container>
    );
}

export default SignUp;
