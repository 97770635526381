


            import React, { useState } from 'react';
            import { Form, Button, Modal } from 'react-bootstrap';
            import { Link, useNavigate } from 'react-router-dom';
            import './ContactPage.css';  // Import the CSS for this component
            
            function ContactPage() {
                const [showModal, setShowModal] = useState(false);
                
                const handleSubmit = async (e) => {
                    e.preventDefault();
                    
                    const formData = {
                        name: e.target.formName.value,
                        email: e.target.formEmail.value,
                        message: e.target.formMessage.value,
                    };
                    
                    try {
                        const response = await fetch('/api/contact/', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify(formData),
                        });
                        
                        if (response.status === 200) {
                            setShowModal(true);
                        } else {
                            console.error('Failed to submit form:', await response.text());
                        }
                    } catch (error) {
                        console.error('Error submitting form:', error);
                    }
                };
            
                return (
                    <div className="contact-page">
                        <h1 className="subtitle2">Struggling to book an interview?</h1>
                        <p className="subheading2">Try our AutoBook service and we guarantee you will get an interview within 7 days or your money back! Please contact us below and we will reach out to you to get started.</p>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formName">
                                <Form.Control type="text" placeholder="Name" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEmail">
                                <Form.Control type="email" placeholder="Email" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formMessage">
                                <Form.Control as="textarea" rows={3} placeholder="Message" />
                            </Form.Group>
                            <Button className="send-button" type="submit">
                                Send
                            </Button>
                        </Form>
            
                        {/* Success Modal */}
                        <Modal show={showModal} onHide={() => setShowModal(false)}>
                            <Modal.Header closeButton>
                                <Modal.Title>Success</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Your message has been sent successfully!</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setShowModal(false)}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                );
            }
            
            export default ContactPage;
            