import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import connect from '../assets/images/Connect.png';
import './SignUp.css';  // Import the CSS for this component
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';

function SignUp() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    
    
    

    const handleConnect = async (event) => {
        event.preventDefault();  // Prevent the default form submission behavior

        try {
            const response = await axios.post(`${API_ENDPOINT}/api/globalentry/authenticate/`, {
                email: email,
                password: password
            });

            if (response.status === 200) {
                
                localStorage.setItem('userEmail', email);

                navigate("/connect2", { state: { previousResponse: response.data } });
            }
        } catch (error) {
            alert("Failed to connect. Please check your credentials and try again.");
        }
    };

    useEffect(() => {
        
    }, []);

    return (
        <div className="signup-page">
        <Container className="signup-container2">
            <img src={connect} className='connect-img' alt="Connect" />
            <h2 className="center-text">Connect to Login.gov</h2>
            <div className="disclaimer">
                We require your Login.gov information to automatically book on your behalf. To learn more please see our <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
            </div>
            <p></p>
            <Form onSubmit={handleConnect}>
                <Form.Group className="mb-3" controlId="formEmail">
                    <Form.Label className="bold-label">Login.gov Email</Form.Label>
                    <Form.Control type="email" value={email} onChange={e => setEmail(e.target.value)} required/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formPassword">
                    <Form.Label className="bold-label">Login.gov Password</Form.Label>
                    <Form.Control type="password" value={password} onChange={e => setPassword(e.target.value)} required/>
                </Form.Group>


                <div className="center-text">
                    <Button variant="primary" type="submit" className="btn-block custom-button">
                        Connect
                    </Button>
                </div>
            </Form>
        </Container>
        </div>
    );
}

export default SignUp;
