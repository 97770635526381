import React from 'react';

function Privacy() {
    const styles = {
        container: {
            fontFamily: 'Arial, sans-serif',
            padding: '2rem',
            maxWidth: '800px',
            margin: 'auto',
            textAlign: 'left'
        },
        header: {
            color: '#333',
            marginTop: '0'
        },
        paragraph: {
            lineHeight: '1.6',
            marginBottom: '1rem'
        },
        subHeader: {
            color: '#333'
        }
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.header}>Easy Global Entry Privacy Policy</h1>
            
            <p style={styles.paragraph}>Last updated: 9/19/2023</p>

            <p style={styles.paragraph}>Thank you for choosing to be part of our community at Easy Global Entry. We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy policy, or our practices with regards to your personal information, please contact us at ryan@easyglobalentry.com or dillon@easyglobalentry.com.</p>

            <h2 style={styles.subHeader}>1. Disclaimer</h2>
            <p style={styles.paragraph}>Easy Global Entry is not affiliated with, endorsed, or sponsored by the US government or Login.gov.</p>

            <h2 style={styles.subHeader}>2. Our Commitment to your Privacy</h2>
            <p style={styles.paragraph}>We take your privacy very seriously. At Easy Global Entry, we emphasize the importance of privacy and are committed to ensuring that your personal data is secure and protected. We do not store any unnecessary credentials or private information of our users.</p>

            <h2 style={styles.subHeader}>3. Limitation of Liability</h2>
            <p style={styles.paragraph}>By using Easy Global Entry, you agree that we are not liable for any direct, indirect, incidental, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses, resulting from the use or inability to use our service.</p>

            <h2 style={styles.subHeader}>4. Changes to this Privacy Policy</h2>
            <p style={styles.paragraph}>We may update our Privacy Policy from time to time. We will notify you of any changes by updating the "Last updated" date of this Privacy Policy. It is advised that you review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

            <h2 style={styles.subHeader}>5. Contact Us</h2>
            <p style={styles.paragraph}>If you have any questions about this Privacy Policy, please contact us directly at ryan@easyglobalentry.com or dillon@easyglobalentry.com.</p>
        </div>
    );
}

export default Privacy;
