import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import Select from 'react-select';
import './SignUp.css';  // Import the CSS for this component
import axios from 'axios';
import { useParams, useLocation } from 'react-router-dom';

function SignUp() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [options, setOptions] = useState([]);

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const fetchLocations = async () => {
        try {
          const response = await axios.get(`${API_ENDPOINT}/api/locations/`);
          const locationOptions = response.data.map(location => ({
            value: location.location_id,
            label: location.location_name
          }));
          setOptions(locationOptions); // set the state
          
        } catch (error) {
          console.error('Error fetching location:', error);
        }
    };
    
    const handleSignUp = async (event) => {
        event.preventDefault();


        if (selectedLocations.length === 0) {
            alert('All fields are required.');
            return;
        }
    

        const subscribedLocations = selectedLocations.map(location => location.value);
        localStorage.setItem('locations', JSON.stringify(subscribedLocations));


        navigate('/calendar');
    };

    useEffect(() => {
        fetchLocations();
    }, []);

    return (
        <div>
        <div className="signup-page">
        <Container className="signup-container2">
            <h2 className="center-text">Select Enrollment Locations</h2>
           
            <Form onSubmit={handleSignUp}>
                <Form.Group className="mb-3" controlId="formLocations">
                    <Form.Label className="bold-label">Locations</Form.Label>                
                    <Select 
                        options={options} 
                        isMulti 
                        isSearchable 
                        onChange={(selectedOptions) => {
                            if (selectedOptions.length > 3) {
                                alert('You can only select up to 3 locations.');
                            } else {
                                setSelectedLocations(selectedOptions);
                            }
                        }}
                        value={selectedLocations}
                    />
                </Form.Group>

                <div className="disclaimer">
                Note: We will search for availbility at any of the selected locations. 
                </div>

                <div className="center-text">
                    <Button variant="primary" type="submit" className="btn-block custom-button">
                        Confirm Locations
                    </Button>
                </div>
            </Form>
        </Container>
        </div>
        </div>
    );
}

export default SignUp;
