import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Container } from 'react-bootstrap';
import './SignUp.css';  // Import the CSS for this component
import './SignUpPage.css';  // Import the CSS for this component

function SignUpPage() {
    return (
        <div className="signup-page">
            <Container className="signup-container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <lottie-player 
                src="https://lottie.host/e42c7a15-0e60-4c7d-bf61-72e5b3ba1fbe/UR9L0OEutA.json" 
                background="transparent" 
                speed="1" 
                style={{ width: '15%', flexShrink: 0 }}
                loop 
                autoplay>
            </lottie-player>
            <h2 className="center-text">You have successfully purchased Easy Global Entry Autobook!</h2>
            <p className="center-text"> We will notify you at your Global Entry account email address when we book your appointment. If we are not able to book you an interview time that is in the next 10 business days then you will be automatically refunded.</p>
        </Container>
        </div>
    );
}

export default SignUpPage;