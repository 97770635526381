import React from 'react';
import './LottieComponent.css'; // Import your CSS file

function LottieComponent() {
  return (
    <div className="lottie-container">
      <div className="lottie-content">   
        <lottie-player 
          src="https://lottie.host/15f3c284-1c77-4d25-bb9e-9ebb631a37bf/CRjgOd0Xg9.json" 
          background="transparent" 
          speed="1" 
          className="lottie-player" // Use className to apply styles
          loop 
          autoplay>
        </lottie-player>
        <div className="lottie-text">
          <h1>We ensure you get the best possible time.</h1>
          <p>
          Our products utilize advanced algorithms to help you secure appointments that align perfectly with your travel plans.
          </p>
        </div>
      </div>
    </div>
  );
}

export default LottieComponent;

