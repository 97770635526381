import React from 'react';
import SignUp from './SignUp';  // Import the SignUp component
import './SignUpPage.css';  // Import the CSS for this component

function SignUpPage() {
    return (
        <div className="signup-page">
            <SignUp />
        </div>
    );
}

export default SignUpPage;
