import React, { useState } from 'react';
import './Calendar.css'; 
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import { Form, Button, Container } from 'react-bootstrap';


const TimeSlotPicker = () => {

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [isDragging, setIsDragging] = useState(false);

    const [currentDayIndex, setCurrentDayIndex] = useState(0); // State for current displayed day

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1); // Move to tomorrow
    
    const getWeekdays = (startDate, numDays) => {
        const weekdays = [];
        let date = new Date(startDate);
        while (weekdays.length < numDays) {
            if (date.getDay() !== 0 && date.getDay() !== 6) { // Skip Sunday (0) and Saturday (6)
                weekdays.push(new Date(date));
            }
            date.setDate(date.getDate() + 1);
        }
        return weekdays;
    };

    const days = getWeekdays(currentDate, 10);



    const handleMouseDown = () => {
        setIsDragging(true);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleSlotToggle = (slot) => {
        if (isDragging) {
            toggleSlot(slot);
        }
    };

    const toggleSlot = (slot) => {
        setSelectedSlots((prev) => {
            if (prev.includes(slot)) {
                return prev.filter((s) => s !== slot);
            }
            return [...prev, slot];
        });
    };

    // Convert hours to AM/PM format
    const formatTime = (hours, minutes) => {
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours %= 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        return hours + ':' + minutes + ampm;
    };

    const nextPage = () => {
        localStorage.setItem('selectedSlots', JSON.stringify(selectedSlots));
        navigate('/connect')
    };

    const navigate = useNavigate();

    return (
        <div>
        <div className="calendar-header">Select your available times over the next ten business days.
        <br></br>
        When there is an interview within your availble times we will automatically book it.</div>        
        <div className="card-wrapper">
        <div className="card">
            <div className="date-navigation">
                <button 
                    onClick={() => setCurrentDayIndex(prev => Math.max(prev - 1, 0))}
                    disabled={currentDayIndex === 0}
                >
                    ←
                </button>
                <h4>
                    {`${days[currentDayIndex].toLocaleString('en-US', { weekday: 'long' })}, ${days[currentDayIndex].toLocaleString('en-US', { month: 'short' })} ${days[currentDayIndex].getDate()}`}
                </h4>
                <button 
                    onClick={() => setCurrentDayIndex(prev => Math.min(prev + 1, days.length - 1))}
                    disabled={currentDayIndex === days.length - 1}
                >
                    →
                </button>
            </div>

            {/* Display the current day and its timeslots */}
            <div className="grid" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
                <div className="column">
                    {Array.from({ length: 16 }).map((_, index) => {
                        const hours = 7 + index;
                        const formattedTime = formatTime(hours, "00");
                        const slot = `${days[currentDayIndex].toDateString()} ${formattedTime}`;
                        return (
                            <div
                                key={slot}
                                className={`time-slot ${selectedSlots.includes(slot) ? 'selected' : ''}`}
                                onMouseOver={() => handleSlotToggle(slot)}
                                onClick={() => toggleSlot(slot)}
                            >
                                {formattedTime}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
        </div>
        <button className="buy-now" onClick={nextPage}>Confirm Times</button>
        </div>
    );
};

export default TimeSlotPicker;
